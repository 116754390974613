import React from 'react'

import about_styles from "./AboutUs_styles.module.css";

import aboutus_vid from "./assets/AboutUs_Vid.mp4";

export default function AboutUs() {
    return (
        <div className={`${about_styles.AboutUsParent} d-flex flex-column justify-content-around align-items-center`}>
            <h1 className={`${about_styles.AboutUsHead} text-center`}>About Us</h1>
            <p className={`${about_styles.AboutUsParagraph}`}>A free and open-source aftermarket distribution, based on the Android mobile platform, aiming to give you the cleanest possible alternative and a perfectly stable, smooth and beautiful experience right out of the box.</p>
            <video controls autoPlay muted loop className={about_styles.AboutUsVideo}>
                <source src={aboutus_vid} type="video/mp4"></source>
                Unable to load
            </video>
        </div>
    )
}
