import React from 'react'

import doc_styles from './Documentation_styles.module.css';

export default function Documentation() {
    return (
        <div className={`${doc_styles.DocParent} d-flex flex-column justify-content-around align-items-center`}>
            <h1 className={`${doc_styles.DocHead} text-center`}>Documentation</h1>
            <p className={`${doc_styles.DocParagraph}`}>
                Page under construction
            </p>
        </div>
    )
}
