import Welcome from './Welcome';
import AboutUs from './AboutUs';
import WhatWeDo from './WhatWeDo';

function Home() {

    document.body.style.backgroundColor = "rgba(7,7,7,1)";
    document.body.style.padding = "0";
    document.body.style.margin = "0";

    return (
        <div className="Homepage_ParentDiv">


            <Welcome />

            {/* <div className="Homepage_WelcomePage">
                <div className="Homepage_WelcomePageText">
                    <h1><strong>Welcome to Styx Project</strong></h1>
                    <h3>The OS everyone has been waiting for.</h3>
                </div>
                <ReactBootstrap.Button href="http://www.google.com/" target="_blank" variant="primary">Know More</ReactBootstrap.Button>{' '}
            </div> */}

            <AboutUs />

            <WhatWeDo />
        </div>
    );
}

export default Home;