import React from 'react'
// import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
// import { Link } from 'react-router';
import { NavLink } from "react-router-dom";

import * as ReactBootstrap from 'react-bootstrap';

import nb_styles from "./Navbar_styles.module.css"

import logo from "./assets/Logo.png";

export default function Navbar() {
    return (
        <div className={`${nb_styles.NavbarParent}`}>
            <ReactBootstrap.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className={`${nb_styles.NavbarMain}`}>
                <ReactBootstrap.Container>
                    <ReactBootstrap.Navbar.Brand href="src/App.jsx">
                        {/* React-Bootstrap */}
                        <NavLink
                            className={`${nb_styles.links} text-white`}
                            activeClassName="is-active"
                            to="/"
                            exact
                        >
                            <ReactBootstrap.Image src={logo} fluid />

                        </NavLink>
                        {/* <ReactBootstrap.Image src={logo} fluid /> */}
                    </ReactBootstrap.Navbar.Brand>
                    <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
                        <ReactBootstrap.Nav className="me-auto">
                            {/* <ReactBootstrap.Nav.Link href="#features">Features</ReactBootstrap.Nav.Link>
                            <ReactBootstrap.Nav.Link href="#pricing">Pricing</ReactBootstrap.Nav.Link>
                            <ReactBootstrap.NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                                <ReactBootstrap.NavDropdown.Item href="#action/3.1">Action</ReactBootstrap.NavDropdown.Item>
                                <ReactBootstrap.NavDropdown.Item href="#action/3.2">Another action</ReactBootstrap.NavDropdown.Item>
                                <ReactBootstrap.NavDropdown.Item href="#action/3.3">Something</ReactBootstrap.NavDropdown.Item>
                                <ReactBootstrap.NavDropdown.Divider />
                                <ReactBootstrap.NavDropdown.Item href="#action/3.4">Separated link</ReactBootstrap.NavDropdown.Item>
                            </ReactBootstrap.NavDropdown> */}
                        </ReactBootstrap.Nav>
                        <ReactBootstrap.Nav className={`${nb_styles.rightlinks_parent}`}>
                            {/* <ReactBootstrap.Nav.Link href="/Teams_page/Team.jsx" className={`${nb_styles.links} text-white`}>Team</ReactBootstrap.Nav.Link> */}
                            {/* <ReactBootstrap.Nav.Link href="#deets" className={`${nb_styles.links} text-white`}>Downloads</ReactBootstrap.Nav.Link> */}
                            {/* <ReactBootstrap.Nav.Link href="#deets" className={`${nb_styles.links} text-white`}>Contact</ReactBootstrap.Nav.Link> */}

                            <ReactBootstrap.Nav.Link>
                                <NavLink
                                    className={`${nb_styles.links} ${nb_styles.linksright} text-white`}
                                    activeClassName="is-active"
                                    to="/downloads"
                                    exact
                                >
                                    Downloads
                                </NavLink>
                            </ReactBootstrap.Nav.Link>
                            <ReactBootstrap.Nav.Link>
                                <NavLink
                                    className={`${nb_styles.links} ${nb_styles.linksright} text-white`}
                                    activeClassName="is-active"
                                    to="/contact"
                                    exact
                                >
                                    Contact
                                </NavLink>
                            </ReactBootstrap.Nav.Link>

                            {/* <ReactBootstrap.Nav.Link eventKey={2} href="#memes">
                                Dank memes
                            </ReactBootstrap.Nav.Link> */}
                        </ReactBootstrap.Nav>
                    </ReactBootstrap.Navbar.Collapse>
                </ReactBootstrap.Container>
            </ReactBootstrap.Navbar>
        </div>
    );
}
