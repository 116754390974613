import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
// import { BrowserRouter, Switch, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";

import * as ReactBootstrap from 'react-bootstrap';

import footer_styles from "./Footer_styles.module.css"

import logo from "./assets/Logo.png";

export default function Footer() {
    return (

        <div className={`${footer_styles.FooterParent} d-flex flex-row align-items-center justify-content-center m-0`}>
            <style>
                {`
                .LinksMember {
                    background-color:red;
                }
                li > a{
                    background-color:#64dd17;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    font-weight:bold;
                }
                `}
            </style>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

            {/* <MDBFooter color="blue" className="font-small pt-4 mt-4"> */}
            <MDBFooter color="" className="font-small p-0 m-0">
                <MDBContainer fluid className='mt-4 m-0 pl-lg-0'>
                    <MDBRow className={`pl-3 pl-sm-3 pl-md-3 pl-lg-5 pl-xl-3`}>
                        {/* heading + desp */}
                        <MDBCol xs="11" sm="11" md="8" lg="7" xl="6" className="">
                            <img src={logo} alt="Logo" className={`${footer_styles.LogoDespPic} m=0 p-0`}></img>
                            <h5 className={`${footer_styles.FooterHead} title ml-0 mr-0 mb-0 p-0 mt-4`}>The Styx Project.</h5>
                            <p className={`mt-1`}>
                                A Cartel Project Initiative
                            </p>
                            <p className={`mt-4`}>
                                Source Code is publicly available to sync and compile at our GitHub Organization, along with the device source code at our Devices organization.
                            </p>
                        </MDBCol>

                        {/* extra space */}
                        <MDBCol xs="4" sm="1" md="1" lg="1" xl="2" className={`${footer_styles.FooterExtraSpace}`}></MDBCol>

                        {/* links */}
                        <MDBCol xs="12" sm="12" md="4" lg="4" xl="4" className={`${footer_styles.Links} mt-sm-0 mt-md-4 mt-lg-4 mt-xl-4 mt-xxl-4`}>
                            {/* <h5 className="title">Links</h5> */}
                            <ul className={`${footer_styles.LinksMemberUl}`}>
                                <li className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}>
                                    {/* <a href="#!" className={`light-green-text`}>Home</a> */}
                                    <NavLink
                                        className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}
                                        activeClassName="is-active"
                                        to="/"
                                        exact
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}>
                                    {/* <a href="#!" className={`light-green-text`}>Downloads</a> */}
                                    <NavLink
                                        className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}
                                        activeClassName="is-active"
                                        to="/downloads"
                                        exact
                                    >
                                        Downloads
                                    </NavLink>
                                </li>
                                <li className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}>
                                    {/* <a href="#!" className={`light-green-text`}>Contact</a> */}
                                    <NavLink
                                        className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}
                                        activeClassName="is-active"
                                        to="/contact"
                                        exact
                                    >
                                        Contact
                                    </NavLink>
                                </li>
                                <li className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}>
                                    {/* <a href="#!" className={`light-green-text`}>Documentation</a> */}
                                    <NavLink
                                        className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}
                                        activeClassName="is-active"
                                        // to="/documentation"
                                        to="/documentation"
                                        exact
                                    >
                                        Documentation
                                    </NavLink>
                                </li>
                                <li className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}>
                                    {/* <a href="#!" className={`light-green-text`}>Documentation</a> */}
                                    <NavLink
                                        className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}
                                        activeClassName="is-active"
                                        // to="/documentation"
                                        to="/faq"
                                        exact
                                    >
                                        FAQs
                                    </NavLink>
                                </li>
                            </ul>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <hr width="100%" className={`${footer_styles.HorizontalLine}`}></hr>

                {/* <div className={`${footer_styles.FooterBottomParent}`}> */}
                <MDBContainer className={`${footer_styles.FooterBottom} d-flex flex-row justify-content-between ml-0 pl-0`}>
                    {/* &copy; {new Date().getFullYear()} Copyright: <a href="https://www.mdbootstrap.com"> MDBootstrap.com </a> */}
                    <MDBRow className={`${footer_styles.FooterBottomRow} ml-0 pl-sm-3 pl-md-3 pl-lg-5 p-xl-0`}>
                        <MDBCol sm="12" md="5" lg="6" xl="5"><p className={`${footer_styles.FooterBottomParagraph} ${footer_styles.FooterBottomParagraph1} p-0 m-0 pr-md-5`}>Copyright 2021 | The Styx Project | All rights reserved.</p></MDBCol>
                        <MDBCol sm="12" md="4" lg="4" xl="4"><p className={`${footer_styles.FooterBottomParagraph} ${footer_styles.FooterBottomParagraph2} p-0 m-0 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0`}>Made with &#10084; by the Styx Family</p></MDBCol>
                        <MDBCol sm="12" md="2" lg="2" xl="3"><div className={`${footer_styles.FooterBottomIconDiv} p-0 m-0 pl-md-3 mt-sm-3 mt-md-0 mt-lg-0 mt-xl-0 ml-xl-5 mt-xxl-0`}>
                            {/* LO LO LO LO */}
                            {/* <MDBRow md="6"> */}
                            {/* <i class="fa fa-facebook-square"></i> */}
                            {/* <i class="fa fa-twitter-square"></i> */}
                            {/* </MDBRow> */}
                            {/* <MDBRow md="6"> */}
                            {/* <i class="fa fa-linkedin-square"></i> */}
                            {/* <i class="fa fa-github-square"></i> */}

                            {/* <NavLink
                                // className={`${footer_styles.LinksMember} list-unstyled m-0 mt-sm-1 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3 p-0`}
                                activeClassName="is-active"
                                // to="/documentation"
                                // to="/faq"
                                to="https://www.github.com/StyxProject"
                                exact
                            >
                        </NavLink> */}

                            <ReactBootstrap.Nav.Link href="https://twitter.com/StyxProject"
                                target="_blank"
                                className={`${footer_styles.bottomMostLinks}`}
                            >
                                <i class="fa fa-twitter-square"></i>
                            </ReactBootstrap.Nav.Link>
                            <ReactBootstrap.Nav.Link href="https://www.github.com/StyxProject"
                                target="_blank"
                                className={`${footer_styles.bottomMostLinks}`}
                            >
                                <i class="fa fa-github-square"></i>
                            </ReactBootstrap.Nav.Link>



                            {/* </MDBRow> */}
                        </div>
                        </MDBCol>
                    </MDBRow>
                    {/* <p>Copyright 2021 | The Styx Project | All rights reserved.</p>
                        <p>Made with &#10084; by the Styx Family</p>
                        <div className={`${footer_styles.FooterBottomIconDiv} mr-5`}>
                            <i class="fa fa-facebook-square"></i>
                            <i class="fa fa-twitter-square"></i>
                            <i class="fa fa-linkedin-square"></i>
                            <i class="fa fa-github-square"></i>
                        </div> */}
                </MDBContainer>
                {/* </div> */}
            </MDBFooter>
        </div >
    )
}

