import React from 'react'

import downloads_styles from './Downloads_styles.module.css';

export default function Downloads() {
    return (
        <div className={`${downloads_styles.DownloadsParent} d-flex flex-column justify-content-around align-items-center`}>
            <h1 className={`${downloads_styles.DownloadsHead} text-center`}>Downloads</h1>
            <p className={`${downloads_styles.DownloadsParagraph}`}>
                Page under construction
            </p>
        </div>
    )
}
