import { BrowserRouter, Switch, Route } from "react-router-dom";

// import app_styles from './App_styles.module.css';
import Home from './HomePage';
import Navbar from './Navbar';
import Footer from "./Footer";
import Downloads from "./Other_pages/Downloads";
import Contact from "./Other_pages/Contact";
import Documentation from "./Other_pages/Documentation";
import FAQ from "./Other_pages/FAQ";

function App() {

    document.body.style.backgroundColor = "rgba(7,7,7,1)";
    document.body.style.padding = "0";
    document.body.style.margin = "0";

    return (
        <BrowserRouter>
            <div className="App_ParentDiv">

                <Navbar />

                <Switch>

                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/downloads">
                        <Downloads />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/documentation">
                        <Documentation />
                    </Route>
                    <Route exact path="/faq">
                        <FAQ />
                    </Route>

                </Switch>

                <Footer />

            </div>
        </BrowserRouter>
    );
}

export default App;