import React from 'react'
import * as ReactBootstrap from 'react-bootstrap';

import whatwedo_styles from './WhatWeDo_styles.module.css'

import whatwedo_icon1 from "./assets/WhatWeDoIcon1.png"
import whatwedo_icon2 from "./assets/WhatWeDoIcon2.png"
import whatwedo_icon3 from "./assets/WhatWeDoIcon3.png"
import whatwedo_icon4 from "./assets/WhatWeDoIcon4.png"

export default function WhatWeDo() {
    return (
        <div className={`${whatwedo_styles.WhatWeDoParent} d-flex flex-column justify-content-around align-items-center`}>
            <h1 className={`${whatwedo_styles.WhatWeDoHead} text-center`}>What Do We Do</h1>
            <p className={`${whatwedo_styles.WhatWeDoParagraph}`}>We aim to provide a simple, clean, rock stable and minimalistic experience right out of the box.</p>

            <div className={`${whatwedo_styles.WhatWeDoGrid}`}>
                <ReactBootstrap.Container fluid>
                    <ReactBootstrap.Row xs={1} sm={1} md={2} lg={2} xl={2} className={`${whatwedo_styles.WhatWeDoGridRow}`}>
                        <ReactBootstrap.Col sm={12} xl={6} className={`${whatwedo_styles.WhatWeDoGridMember} ${whatwedo_styles.WhatWeDoGridMember1}`}>
                            <img src={whatwedo_icon1} alt="Icon1" className={whatwedo_styles.WhatWeDoGridMemberIcon}></img>
                            <div className={`${whatwedo_styles.WhatWeDoGridMemberContent} ${whatwedo_styles.WhatWeDoGridMember1Content}`}>
                                <h3 className={whatwedo_styles.WhatWeDoGridMemberHead}>Faster Security Updates</h3>
                                <p className={whatwedo_styles.WhatWeDoGridMemberParagraph}>With AOSP security patches, Linux and CodeAurora tags being merged within hours of its release, you are sure to receive the latest security fixes and updates on your device as soon as possible!</p>
                            </div>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col sm={12} className={`${whatwedo_styles.GridFillers} ${whatwedo_styles.GridFillers1}`}>
                            <img src={whatwedo_icon1} alt="Icon1" className={whatwedo_styles.WhatWeDoGridFillersIcon}></img>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col sm={12} className={`${whatwedo_styles.GridFillers} ${whatwedo_styles.GridFillers2}`}>
                            <img src={whatwedo_icon2} alt="Icon2" className={whatwedo_styles.WhatWeDoGridFillersIcon}></img>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col sm={12} xl={6} className={`${whatwedo_styles.WhatWeDoGridMember} ${whatwedo_styles.WhatWeDoGridMember2}`}>
                            <img src={whatwedo_icon2} alt="Icon2" className={whatwedo_styles.WhatWeDoGridMemberIcon}></img>
                            <div className={`${whatwedo_styles.WhatWeDoGridMemberContent} ${whatwedo_styles.WhatWeDoGridMember2Content}`}>
                                <h3 className={whatwedo_styles.WhatWeDoGridMemberHead}>Secure and Bloat-free</h3>
                                <p className={whatwedo_styles.WhatWeDoGridMemberParagraph}>The ROM doesn't ship with any sort of bloat, and many hardening patches are in the process of being merged to give you the most secure and bloatfree experience that you’ve always wanted.</p>
                            </div>
                        </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                    <ReactBootstrap.Row xs={1} sm={1} md={2} lg={2} xl={2} className={`${whatwedo_styles.WhatWeDoGridRow} ${whatwedo_styles.WhatWeDoGridRow2}`}>
                        <ReactBootstrap.Col sm={12} className={`${whatwedo_styles.WhatWeDoGridMember} ${whatwedo_styles.WhatWeDoGridMember3}`}>
                            <img src={whatwedo_icon3} alt="Icon3" className={`${whatwedo_styles.WhatWeDoGridMemberIcon} ${whatwedo_styles.WhatWeDoGridMemberIcon3}`}></img>
                            <div className={`${whatwedo_styles.WhatWeDoGridMemberContent} ${whatwedo_styles.WhatWeDoGridMember3Content}`}>
                                <h3 className={whatwedo_styles.WhatWeDoGridMemberHead}>Rock Stable</h3>
                                <p className={whatwedo_styles.WhatWeDoGridMemberParagraph}>We work on the ROM to make it extremely stable; we believe in the user not facing any issues regarding their device, flash and forget.</p>
                            </div>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col sm={12} className={`${whatwedo_styles.GridFillers} ${whatwedo_styles.GridFillers3}`}>
                            <img src={whatwedo_icon3} alt="Icon3" className={`${whatwedo_styles.WhatWeDoGridFillersIcon}`}></img>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col sm={12} className={`${whatwedo_styles.GridFillers} ${whatwedo_styles.GridFillers4}`}>
                            <img src={whatwedo_icon4} alt="Icon4" className={whatwedo_styles.WhatWeDoGridFillersIcon}></img>
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col sm={12} className={`${whatwedo_styles.WhatWeDoGridMember} ${whatwedo_styles.WhatWeDoGridMember4}`}>
                            <img src={whatwedo_icon4} alt="Icon4" className={`${whatwedo_styles.WhatWeDoGridMemberIcon} ${whatwedo_styles.WhatWeDoGridMemberIcon4}`}></img>
                            <div className={`${whatwedo_styles.WhatWeDoGridMemberContent} ${whatwedo_styles.WhatWeDoGridMember4Content}`}>
                                <h3 className={whatwedo_styles.WhatWeDoGridMemberHead}>Smooth and Beautiful</h3>
                                <p className={whatwedo_styles.WhatWeDoGridMemberParagraph}>Styx Project is a distribution that strives to be really minimalistic and beautiful right out of the box.{/*  We merge patches from master and other sources to give you the best performance out of your device. */}</p>
                            </div>
                        </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                </ReactBootstrap.Container>
            </div>
        </div>
    )
}
