import React from 'react'

import ws_styles from "./Welcome_styles.module.css";

export default function Welcome() {
    return (
        <div className={`${ws_styles.WelcomeParent} d-flex flex-column justify-content-around align-items-center`}>
            {/* <Navbar /> */}

            <div className={`${ws_styles.WelcomeText} text-center`}>
                {/* <div className="text-center"> */}
                <h1><strong>Welcome to <span className={`${ws_styles.WelcomeTextSpan}`}>Styx Project</span></strong></h1>
                <p className={`${ws_styles.WelcomeTextParagraph}`}>The OS everyone has been waiting for.</p>
            </div>
        </div>
    )
}
