import React from 'react'

import faq_styles from './Faq_styles.module.css';

export default function FAQ() {
    return (
        <div className={`${faq_styles.FaqParent} d-flex flex-column justify-content-around align-items-center`}>
            <h1 className={`${faq_styles.FaqHead} text-center`}>FAQs</h1>
            <p className={`${faq_styles.FaqParagraph}`}>
                Page under construction
            </p>
        </div>
    )
}
