import React from 'react'

import contact_styles from './Contact_styles.module.css';

export default function Contact() {
    return (
        <div className={`${contact_styles.ContactParent} d-flex flex-column justify-content-around align-items-center`}>
            <h1 className={`${contact_styles.ContactHead} text-center`}>Contact</h1>
            <p className={`${contact_styles.ContactParagraph}`}>
                Page under construction
            </p>
        </div>

    )
}
